import styled from "@emotion/styled";

const Content = styled.div`
  width: 1920px;
  height: 1080px;
  margin: auto;
  display: flex;
  background-color: white;
`;

export default Content;
