import React from "react";
import sampleSize from "lodash/sampleSize";
import Content from "./components/content";
import styled from "@emotion/styled";
import Logo from "./components/logo";

const columnNames = [
  "css/custom-property",
  "css/modify-custom-property",
  "css/multiple-linear-gradients",
  "react/debounce-hook",
  "react/lazy-loading",
  "react/error-boundaries",
  "typescript/partial",
  "typescript/pick",
  "typescript/omit",
  "typescript/return-type",
  "lodash/xor",
  "lodash/sample",
  "lodash/sample-size",
  "lodash/without",
  "javascript/capitalize-string",
  "javascript/search-params-from-entries",
  "javascript/document-fragment",
  "javascript/repeat-string",
  "javascript/convert-string-to-a-number",
  "javascript/logical-assignment-operator",
  "javascript/nullish-coalescing",
  "guidelines/estimations",
  "guidelines/asking-for-help",
  "guidelines/planning",
  "guidelines/build-an-mvp",
  "guidelines/meetings",
  "psychology/four-stages-of-competence",
  "performance/will-change",
  "performance/intersection-observer",
  "performance/webpack-bundle-analyzer",
  "html/change-download-filename",
  "html/details-summary",
  "html/output-element",
];

const UPDATE_DAILY = false;
const REFRESH_INTERVAL = 60000;

const getRandomColumns = () =>
  sampleSize(columnNames, 3).map((name) =>
    React.lazy(() => import(`./columns/` + name))
  );

const App = () => {
  const [columns, setColumns] = React.useState(getRandomColumns());
  const [today, setToday] = React.useState(new Date().getDate());



  React.useEffect(() => {
    const refreshInterval = setInterval(() => {
      const now = new Date();

      if (UPDATE_DAILY === false) {
        setColumns(getRandomColumns());
      }

      if (today !== now.getDate()) {
        setToday(now.getDate());
        setColumns(getRandomColumns());
      }
    }, REFRESH_INTERVAL);

    return () => clearInterval(refreshInterval);
  }, [today]);

  const [One, Two, Three] = columns;

  return (
    <Screen>
      <Scale>
        <Content>
          <Debug>
            {window.innerWidth} &times; {window.innerHeight} &nbsp; &middot;
            &nbsp;
            {getScreenRatio()} &nbsp; &middot; &nbsp;{" "}
            {Math.round(REFRESH_INTERVAL / 1000)}sec
          </Debug>

          <React.Suspense fallback={null}>
            <One />
            <Two />
            <Three />
          </React.Suspense>
          <Logo/>
        </Content>
      </Scale>
    </Screen>
  );
};

const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Scale = styled.div`
  width: 1920px;
  height: 1080px;
  transform: scale(
    ${Math.min(window.innerWidth / 1920, window.innerHeight / 1080)}
  );
`;

const getScreenRatio = () => {
  const ratio = window.innerWidth / window.innerHeight;

  if (ratio === 16 / 10) {
    return "16:10";
  }

  if (ratio === 16 / 9) {
    return "16:9";
  }

  if (ratio === 4 / 3) {
    return "4:3";
  }

  return ratio.toPrecision(3);
};

const Debug = styled.div`
  z-index: 9000;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 9px;
  padding: 4px;
  color: lightgray;
`;

export default App;
