import styled from "@emotion/styled";

const Logo = styled.div`
  border: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 10vw;
  height: 10vh;
  z-index: 1;
  background-image: url("assets/images/500-logo.png");
  background-repeat: no-repeat;
  background-size: 75%;
`;

export default Logo;
